/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqui from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Platzi from "@components/sharedComponents/customersLogos/logos/Platzi.svg"
import Unifef from "@components/sharedComponents/customersLogos/logos/Unifef.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Alfin from "@components/sharedComponents/customersLogos/logos/Alfin.svg"
import Civa from "@components/sharedComponents/customersLogos/logos/Civa.svg"
import G4S from "@components/sharedComponents/customersLogos/logos/G4S.svg"
import Ibr from "@components/sharedComponents/customersLogos/logos/Ibr.svg"
import Conexo from "@components/sharedComponents/customersLogos/logos/Conexo.svg"
import Eriza from "@components/sharedComponents/customersLogos/logos/3eriza.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/cold-calling-software.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/contact-center-omnicanal.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/como-administrar-call-center.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/proveedores-telefonia-ip.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/auto-dialer-software.jpg"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageFunctionalities/pageVirtualPBX/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageFunctionalities/pageVirtualPBX/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageFunctionalities/pageVirtualPBX/images/accordionLeft/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageFunctionalities/pageVirtualPBX/images/accordionLeft/imageFour.webp"
import ImageAccordionLeftFive from "@components/pageFunctionalities/pageVirtualPBX/images/accordionLeft/imageFive.webp"


import imageVideo from "@components/sharedComponents/bannerVideo/image/imageVirtualPBX.jpg"

import { TitleUnderlineVirtualPBXOne, TitleUnderlineVirtualPBXTwo } from "@components/pageFunctionalities/components/intro/titleUnderlineVirtualPBX"
import imageStartVirtualPBX from "@components/pageFunctionalities/components/intro/images/imageStartVirtualPBX.webp"

//iconos poteciamos operaciones
import televentas from "@components/pageHome/operations/images/imageTwo.webp"
import telemarketing from "@components/pageHome/operations/images/imageThree.webp"
import cobranzas from "@components/pageHome/operations/images/imageSeven.webp"

const titleStart = (
  <p>
    Migra tu <TitleUnderlineVirtualPBXOne underline="central a la" /> <TitleUnderlineVirtualPBXTwo underline="nube" /> con Beex. Escala y automatiza tus campañas bajo un enfoque omnicanal
  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Trabaja tus estrategias inbound y outbound desde la nube e intégralo con herramientas externas como tu CRM o ERP. Además, añade canales digitales como WhatsApp para una interacción más completa con los usuarios.
        <br /><br />
        Asegura el funcionamiento de tus campañas desde cualquier lugar y con cualquier dispositivo. Obtén marcadores inteligentes, autoservicio con IA, crea enrutamientos inteligentes y mucho más.
      </p>
    ),
    image: imageStartVirtualPBX,
    textbutton: "Habla con un experto",
    link: "/hablemos/",
  },
  customersDesktop: [
    Rimac,
    Culqui,
    Platzi,
    Unifef,
    Auna,
    Alfin,
    Civa,
    G4S,
    Ibr,
    Conexo,
    Eriza,
    Astara,
  ],
  customersTablet: {
    arrayOne: [Rimac, Culqui, Platzi, Unifef, Auna, Alfin],
    arrayTwo: [Civa, G4S, Ibr, Conexo, Eriza, Astara],
  },
  articles: [
    {
      image: article1,
      title: "14 preguntas para elegir el mejor software de Contact Center",
      textbody:
        "Te contamos qué consideraciones debes tener en cuenta para elegir el mejor software para call center y empieces a mejorar tus campañas.",
      link: "https://beexcc.com/blog/mejor-software-call-center/",
      card: true,
    },
    {
      image: article2,
      title: "Contact Center omnicanal: Lo que todo líder de equipo debe saber",
      textbody:
        "Si quieres aprender sobre un Contact Center omnicanal, en este artículo creamos una guía con toda la información esencial.",
      link: "https://beexcc.com/blog/contact-center-omnicanal/",
      card: true,
    },
    {
      image: article3,
      title: "Cómo administrar un call center para hacerlo crecer",
      textbody:
        "Aquí te contamos cómo administrar un call center, cuáles son los tipos que existen y sus principales funciones.",
      link: "https://beexcc.com/blog/como-administrar-call-center/",
    },
    {
      image: article4,
      title: "7 proveedores de telefonía IP que debes conocer para este 2024",
      textbody:
        "Recopilamos los mejores proveedores de telefonía IP que todo Contact Center debe considerar para el 2024.",
      link: "https://beexcc.com/blog/proveedores-telefonia-ip/",
    },
    {
      image: article5,
      title: "Auto Dialer Software: Top 5 de las plataformas para el 2024",
      textbody:
        "Entérate cuáles son las 5 plataformas que te ofrecen Auto Dialer Software. Así elegirás la mejor para tu empresa.",
      link: "https://beexcc.com/blog/auto-dialer-software/",
    },
  ],
  contacts: {
    title: "Lleva tus operaciones telefónicas a un siguiente nivel",
    textbody: "Queremos ayudarte a potenciar tus estrategias de comunicación telefónica. Déjanos saber cuáles son tus necesidades y nuestros agentes se pondrán en contacto contigo.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  accordionRight: [
    {
      title: "Crea un autoservicio con IVR con IA",
      description: "<p>Permite que tus clientes encuentren respuestas rápidamente sin esperar a un agente. Nuestra plataforma utiliza IVR con IA para entender y resolver consultas de forma eficiente. Mejora la experiencia del cliente con soluciones inmediatas a sus problemas.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Integración con canales digitales",
      description: "<p>Conecta con tus clientes donde prefieran, ya sea por chat como WhatsApp, email o redes sociales. Esta integración asegura una comunicación fluida y coherente. Mantén todas las conversaciones sincronizadas en una sola plataforma.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Adhiere herramientas terceras",
      description: "<p>Conecta tu canal telefónico con herramientas externas Salesforce, HubSpot, SAP, Zoho u otros. De este modo, tendrás una visión 360 grados del cliente, asegurando que cada llamada se maneje con toda la información relevante al alcance.</p>",
      image: ImageAccordionLeftThree,
    },
    {
      title: "Escalabilidad en tus campañas",
      description: "<p>Adapta la capacidad de tu comunicación al ritmo de tu crecimiento. Añade y reduce líneas telefónicas sin ningún problema y al instante. Nuestra plataforma se adapta a las demandas de tus campañas en tiempo real para mejorar tu servicio.</p>",
      image: ImageAccordionLeftFour
    },
    {
      title: "Enrutamientos automatizados",
      description: "<p>Dirige cada llamada al agente más adecuado basado en habilidades y disponibilidad. Este sistema de enrutamiento asegura respuestas más rápidas y efectivas. Mejora la satisfacción del cliente asignando las consultas a quien mejor puede resolverlas.</p>",
      image: ImageAccordionLeftFive
    },
  ],
  bannerVideo: {
    title: "¿Qué es un call Center en la Nube?",
    description: "<p>Conoce qué es un call center en la nube y cuál es el impacto positivo que tiene para continuar con las operaciones de cualquier centro de contacto. <br /><br />    Nuestro CTO, Daniel Tito Barrios, nos explica detalladamente las ventajas de llevar tu Contact Center a la nube y cómo hacerlo.</p>",
    image: imageVideo
  },
  modules: [
    {
      tab: "Customer Experience",
      title: "Atención al cliente",
      img: televentas,
      link: "/atencion-al-cliente/",
      text:
        "Gestiona respuestas inmediatas mediante un autoservicio con IVR y chatbots y facilita el acceso a la información relevante durante la atención para resolver consultas rápidamente.",
      colorTab: "#FFD96E",
      background: "#ffebb7"
    },
    {
      tab: "Conversion",
      title: "Telemarketing",
      img: telemarketing,
      text:
        "Agiliza tus campañas de prospección con nuestros marcadores inteligentes para contactar solo con los prospectos más cercanos a conversión.",
      link: "/marketing-ventas/",
      colorTab: "#AFC9F7",
      background: "#d7e4fb"
    },
    {
      tab: "Payment",
      title: "Cobros",
      img: cobranzas,
      link: "/software-cobranzas/",
      text:
        "Programa llamadas automáticas y personaliza mensajes para recordatorios de pago, asegurando una mejor contactabilidad que mejora tus índices de recuperación.",
      colorTab: "#FFAB99",
      background: "#FFD5CC"
    },
  ],
}

export default data
