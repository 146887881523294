import React from "react"

export const TitleUnderlineVirtualPBXOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="257" height="10" viewBox="0 0 257 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M48.5746 9.5386C43.2854 8.59231 4.53037 9.49703 1.71463 9.20769C-0.583663 8.82474 3.11369 2.23098 5.25152 1.26542C6.00089 0.918256 6.87028 0.870359 8.86123 1.03608C12.018 1.34565 52.851 0.893058 52.851 0.893058C53.3895 1.09835 55.0092 0.41716 55.3055 0.666142C56.2387 1.24792 122.423 0.684371 128.067 0.671759C131.489 0.664311 128.781 1.04932 132.809 0.904051C141.392 0.615298 160.095 0.984054 166.599 0.643728C171.804 0.362573 172.927 0.353679 177.921 0.612266C183.026 0.845794 210.17 0.626468 213.275 0.172857C214.034 0.048271 214.617 0.0947642 214.617 0.241004C214.617 0.272796 233.75 0.445897 233.838 0.560645C233.98 0.707371 234.563 0.779289 235.103 0.679388C236.104 0.530192 256.472 0.0462224 256.79 0.473311C257.787 1.6975 254.964 6.88903 252.594 8.14629C250.169 9.49237 236.883 7.9276 226.196 8.70515L176.453 8.61879L165.789 9.09122C164.569 8.64835 159.868 9.73872 157.032 8.94065C155.833 8.62502 147.361 8.55172 145.49 8.87599C143.861 9.12479 100.523 8.33534 96.075 9.20404C88.1555 8.43956 56.024 9.38588 48.5746 9.5386Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineVirtualPBXTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="121" height="10" viewBox="0 0 121 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.2336 9.96228C20.7621 9.02556 2.638 10.0004 1.32184 9.71614C0.247817 9.33734 1.98868 2.73694 2.99011 1.76751C3.34115 1.419 3.74778 1.36953 4.67848 1.53165C6.15406 1.83551 25.249 1.30905 25.249 1.30905C25.5004 1.51337 26.2591 0.829249 26.3971 1.07769C26.8325 1.65778 57.7821 0.974508 60.4216 0.951685C62.0217 0.938047 60.7547 1.32796 62.6383 1.1754C66.6525 0.871121 75.3979 1.20604 78.4398 0.85395C80.8744 0.563381 81.3992 0.552457 83.7342 0.802008C86.1207 1.0263 98.8144 0.75787 100.267 0.298647C100.622 0.172688 100.895 0.218126 100.894 0.364366C100.894 0.396157 109.841 0.534645 109.882 0.649234C109.948 0.795701 110.221 0.866564 110.473 0.765689C110.942 0.614681 120.467 0.0938693 120.615 0.52038C121.079 1.74276 119.749 6.93936 118.639 8.2009C117.502 9.55136 111.292 8.01063 106.294 8.80751L83.033 8.81114L78.0458 9.30285C77.4762 8.86219 75.2755 9.96106 73.9508 9.16813C73.3911 8.85467 69.4296 8.79669 68.5538 9.12435C67.7915 9.3761 47.5277 8.66505 45.4461 9.54178C41.7443 8.79164 26.7174 9.79608 23.2336 9.96228Z" fill="#FFBA00" />
    </svg>
  </span>
)