import React from "react"

export const TitleUnderlineRightOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
       width="312" height="9" viewBox="0 0 312 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M57.7901 8.33102C51.3753 7.42759 4.34195 8.25375 0.913789 7.99818C-1.89107 7.64156 2.42012 1.47805 4.99125 0.568684C5.90023 0.24773 6.96499 0.18235 9.40626 0.354714C11.3541 0.485473 24.7551 0.467639 37.6887 0.402259C50.6222 0.33688 63.0882 0.259614 63.0882 0.259614C63.7375 0.455753 65.7373 -0.180211 66.1009 0.0515895C66.6722 0.324995 87.1373 0.372544 108.33 0.372544C129.522 0.372544 151.441 0.354711 154.87 0.360655C159.051 0.366598 155.701 0.717274 160.635 0.610289C171.127 0.384432 193.93 0.883691 201.877 0.616229C208.266 0.40226 209.616 0.414147 215.693 0.681609C221.875 0.955015 255.013 0.996621 258.857 0.622174C259.792 0.515189 260.493 0.562737 260.493 0.699439C260.935 0.71727 283.789 1.11549 283.867 1.21653C284.023 1.35324 284.724 1.4305 285.4 1.34135C286.646 1.21059 311.552 1.01445 311.864 1.41862C312.825 2.57762 308.488 7.38004 305.449 8.5331C302.255 9.75154 286.438 8.12894 273.349 8.73518C201.955 8.00412 216.161 8.06356 200.033 8.50338C198.605 8.07544 192.839 9.04425 189.411 8.29536C188.008 7.98629 177.75 7.86742 175.413 8.14677C172.841 8.45584 121.445 7.30277 115.42 8.17054C105.862 7.40976 66.828 8.19432 57.7901 8.33102Z" fill="#FFBA00"/>
</svg>

  </span>
)

export const TitleUnderlineRightTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="101" height="9" viewBox="0 0 101 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.7077 8.33102C16.6311 7.42759 1.40557 8.25375 0.29581 7.99818C-0.612174 7.64156 0.783437 1.47805 1.61576 0.568684C1.91001 0.24773 2.25469 0.18235 3.04497 0.354714C3.67552 0.485473 8.01368 0.467639 12.2005 0.402259C16.3873 0.33688 20.4228 0.259614 20.4228 0.259614C20.633 0.455753 21.2803 -0.180211 21.398 0.0515895C21.583 0.324995 28.2079 0.372544 35.0682 0.372544C41.9285 0.372544 49.0243 0.354711 50.134 0.360654C51.4876 0.366598 50.4031 0.717274 52.0005 0.610289C55.397 0.384432 62.7786 0.883691 65.3512 0.616229C67.4194 0.40226 67.8566 0.414147 69.8239 0.681609C71.8248 0.955015 82.5524 0.996621 83.7967 0.622174C84.0994 0.515189 84.3264 0.562737 84.3264 0.699439C84.4693 0.71727 91.8677 1.11549 91.8929 1.21653C91.9434 1.35324 92.1704 1.4305 92.3889 1.34135C92.7925 1.21059 100.855 1.01445 100.956 1.41862C101.267 2.57762 99.863 7.38004 98.8793 8.5331C97.8453 9.75154 92.7252 8.12894 88.488 8.73518C65.3764 8.00412 69.9752 8.06356 64.7543 8.50338C64.2919 8.07544 62.4254 9.04425 61.3157 8.29536C60.8617 7.98629 57.5408 7.86742 56.7842 8.14677C55.9519 8.45584 39.3139 7.30277 37.3634 8.17054C34.2695 7.40976 21.6334 8.19432 18.7077 8.33102Z" fill="#FFBA00" />
    </svg>
  </span>
)